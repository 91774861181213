"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Hooks;
(function (Hooks) {
    Hooks["MESSAGE_BEFORE_SENT"] = "message::before-sent";
    Hooks["MESSAGE_BEFORE_RECEIVED"] = "message::before-received";
})(Hooks = exports.Hooks || (exports.Hooks = {}));
function hookAdapterFactory() {
    var hooks = {};
    var get = function (key) {
        if (!Array.isArray(hooks[key]))
            hooks[key] = [];
        return hooks[key];
    };
    function intercept(hook, callback) {
        get(hook).push(callback);
        var index = get(hook).length;
        return function () { return get(hook).splice(index, 1); };
    }
    function trigger(hook, payload) {
        return get(hook).reduce(function (acc, fn) { return Promise.resolve(acc).then(fn); }, Promise.resolve(payload));
    }
    return {
        trigger: trigger,
        intercept: intercept,
    };
}
exports.hookAdapterFactory = hookAdapterFactory;

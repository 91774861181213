"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function UrlBuilder(basePath) {
    var params = {};
    var getQuery = function (key, value) { return key + "=" + value; };
    return {
        param: function (key, value) {
            params[key] = value;
            return this;
        },
        build: function () {
            var query = Object.keys(params)
                .filter(function (key) { return params[key] != null; })
                .map(function (key) {
                if (Array.isArray(params[key])) {
                    return params[key]
                        .map(function (value) { return getQuery(key + "[]", value); })
                        .join("&");
                }
                return getQuery(key, params[key]);
            })
                .join("&");
            return [basePath].concat(query).join("?");
        },
    };
}
exports.default = UrlBuilder;
